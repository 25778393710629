import { AppComponent } from './../app.component';
import { Ng2DeviceService } from 'ng2-device-detector';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  deviceInfo = null;
  isMobile = false;
  mobile: string = "default";

  constructor(private deviceService: Ng2DeviceService,
  private router: Router) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.mobile = this.deviceInfo;
    if ( this.deviceService.device == "unknown"){
        this.isMobile = false;     
    }
    else{
      this.isMobile = true;
    }
  }


  readMore() {
    this.router.navigateByUrl("/read-more");
  } 

  ngOnInit() {
  }

}
