import { AlertService } from './../shared/services/alert.service';
import { Component, OnInit, Input} from '@angular/core';
import { FormGroup, FormBuilder } from '../../../node_modules/@angular/forms';
import { NgbActiveModal } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { MailModalService } from './mail-modal.service';

@Component({
  selector: 'app-mail-modal',
  templateUrl: './mail-modal.component.html',
  styleUrls: ['./mail-modal.component.css'],
})
export class MailModalComponent {
  @Input()id: number;
  
  myForm: FormGroup;
  successMessage = false;

  constructor(
   public activeModal: NgbActiveModal,
   private formBuilder: FormBuilder,
   private mailModalService: MailModalService,
   private alertService: AlertService
  ) {
    this.createForm();
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      email: '',
      object: '',
      content: ''
    });
  }
  
  private submitForm() {
    this.successMessage = true;
    this.mailModalService.Submit(this.myForm.value);
    this.activeModal.close(this.myForm.value);
    this.alertService.newEvent("show-alert");
  }
}
