import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '../../../../node_modules/angularfire2/database';
import { Observable } from '../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  News$: Observable<any[]>;

  constructor(private db: AngularFireDatabase) { 
    this.News$ = this.db
    .list('/news')
    .snapshotChanges();  
  }

  create(project){
    return this.db.list('/news').push(project);
  }

  getAll() {
    return this.News$;
  }

  get(projectId) {
    return this.db.object('/news/' + projectId).snapshotChanges();
  }

  update(projectId, project){
    return this.db.object('/news/' + projectId).update(project);
  }

  delete(projectId){
    return this.db.object('news/' + projectId).remove();
  }
}

