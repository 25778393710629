import { AngularFireObject } from 'angularfire2/database/interfaces';
import { AppUser } from '../models/app-user';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class UserService {

  constructor(private db: AngularFireDatabase) { }

  save(user: firebase.User){
    this.db.object('/users/' + user.uid).update({
      name: user.displayName,
      email: user.email,
      key: user.uid
    })
  }

  get( uid: string):  AngularFireObject<AppUser>{
    return this.db.object('/users/' + uid);
  }
}
