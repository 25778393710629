import { INews } from './../models/INews';
import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../../../node_modules/rxjs';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: INews[];
  subscription: Subscription;
  
    constructor(private NewsService: NewsService) { 
      this.subscription = this.NewsService.getAll()
      .map(actions => {
      return actions.map(action => ({ key: action.key, value: action.payload.val() }));
      })
      .subscribe(x => this.news = x)
      }
      
  ngOnInit() {
  }

}
