import { INews } from './../../../../news/models/INews';
import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { NewsService } from '../../../../news/services/news.service';

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.css']
})
export class AdminNewsComponent implements OnInit {
  news: INews[];
  filteredNews: any[];
  subscription: Subscription;
  
    constructor(private NewsService: NewsService) { 
      this.subscription = this.NewsService.getAll()
      .map(actions => {
      return actions.map(action => ({ key: action.key, value: action.payload.val() }));
      })
      .subscribe(x => this.filteredNews
     = this.news = x)
      }
  
      filter(query: string) {
        this.filteredNews
     = (query) ?
        this.news.filter(f => f.value.title.toLowerCase().includes(query.toLowerCase())) :
        this.news;
        }
  
    delete(key){
      if(!confirm('are you sur you want to delete this project?')) return;
      
      this.NewsService.delete(key);
    }

    ngOnDestroy(){
      this.subscription.unsubscribe();
    }
  
    ngOnInit() {
    }
  
}
