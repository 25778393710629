import { IProject } from '../../../../projects/models/Iproject';
import { projectsServiceService } from '../../../../projects/services/projects-service.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/map'

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {
  project= {} as IProject;
  
  id;
  project$: Subscription;

    constructor(
      private ProjectService: projectsServiceService, 
      private router: Router,
      private route: ActivatedRoute) { 
  
      this.id = this.route.snapshot.paramMap.getAll('id');
     
      this.project$ = this.ProjectService.get(this.id).subscribe(
        action => {
          this.project = { key : action.key, value : Object(action.payload.val()) }
          console.log(this.project.value);
          console.log(this.project.key);
        // this.project.value.title = Object.values(this.project.value.title);
      });

     }

  
    save(project){
      if (this.id != "") this.ProjectService.update(this.id, project);
      else this.ProjectService.create(project);
  
      this.router.navigate(['/admin/project'])
    }
  
    delete(){
      if(!confirm('are you sur you want to delete this project?')) return;
      
      this.ProjectService.delete(this.id);
      this.router.navigate(['/admin/project']);
    }
  
    ngOnInit() {
    }

}
