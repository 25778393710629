import { Component, OnInit } from '@angular/core';
import { INews } from '../../../../news/models/INews';
import { NewsService } from '../../../../news/services/news.service';
import { Router, ActivatedRoute } from '../../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {
  news = {} as INews;
  id;

    constructor(
      private NewsService: NewsService, 
      private router: Router,
      private route: ActivatedRoute) { 
  
      this.id = this.route.snapshot.paramMap.getAll('id');

    }
  
    save(News){
      if (this.id != "") this.NewsService.update(this.id, News);
      else this.NewsService.create(News);
  
      this.router.navigate(['/admin/news'])
    }
  
    delete(){
      if(!confirm('are you sur you want to delete this News?')) return;
      
      this.NewsService.delete(this.id);
      this.router.navigate(['/admin/news']);
    }
  
    ngOnInit() {
    }

}

