import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '../../../node_modules/angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class MailModalService {

  constructor(private db: AngularFireDatabase) { }

  Submit(formRequest){
    console.log("mail service called");
    this.db.list('/messages').push(formRequest);
  }
}
