import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/components/news.component';
import { ProjectsComponent } from './projects/projects.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from './../environments/environment';
import { CreateAccountComponent } from './membership/create-account/create-account.component';
import { LoginComponent } from './membership/login/login.component';
import { AccountComponent } from './membership/account/account.component';
import { AdminAuthGuard } from './admin/services/admin-auth-guard.service';
import { AdminModule } from './admin/admin.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { Ng2DeviceDetectorModule } from '../../node_modules/ng2-device-detector';
import { MailModalComponent } from './mail-modal/mail-modal.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { ReadMoreComponent } from './read-more/read-more.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    NewsComponent,
    ProjectsComponent,
    CreateAccountComponent,
    LoginComponent,
    AccountComponent,
    NotfoundComponent,
    MailModalComponent,
    ReadMoreComponent,
  ],
  imports: [
    HttpModule,
    SharedModule,
    AdminModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    Ng2DeviceDetectorModule.forRoot(),
    NgbModule.forRoot(),
    NgbModalModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'news', component: NewsComponent},
      { path: 'projects', component: ProjectsComponent},
      { path: 'login', component: LoginComponent},
      { path: 'contact', component: MailModalComponent },
      { path: 'read-more', component: ReadMoreComponent},
      { path: '**', component: NotfoundComponent},
    ])
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    AdminAuthGuard,
  ],
  bootstrap: [
    AppComponent]
})
export class AppModule { }
