import { AlertService } from './../../services/alert.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from '../../../../../node_modules/rxjs';
import { debounceTime } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage: string;

  constructor(private AlertService: AlertService){
  }
  ngOnInit(): void {
    //subscribe to the event from alertService
    this.AlertService.events$.forEach(event => {  this.changeSuccessMessage()})

    setTimeout(() => this.staticAlertClosed = true, 20000);

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(
      debounceTime(4000)
    ).subscribe(() => this.successMessage = null);

  }

  public changeSuccessMessage() {
    console.log("message sent success");
    this._success.next(`${new Date().getHours().toString()} h: ${new Date().getMinutes().toString()} - Votre message a ete envoye.`);
  }

}
