import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProjectComponent } from './components/projects/edit-project/edit-project.component';
import { AdminProjectsComponent } from './components/projects/admin-projects/admin-projects.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { AdminNewsComponent } from './components/news/admin-news/admin-news.component';
import { AddNewsComponent } from './components/news/add-news/add-news.component';
import { EditNewsComponent } from './components/news/edit-news/edit-news.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CustomFormsModule,
    RouterModule.forRoot([
      { 
          path: 'admin/project', 
          component: AdminProjectsComponent, 
          canActivate: [AuthGuard, AdminAuthGuard] 
      },
    { 
        path: 'admin/project/add', 
        component: AddProjectComponent,  
        canActivate: [AuthGuard, AdminAuthGuard]
    },
    { 
        path: 'admin/project/edit/:id', 
        component: EditProjectComponent,
        canActivate: [AuthGuard, AdminAuthGuard]
    },
    { 
      path: 'admin/news', 
      component: AdminNewsComponent, 
      canActivate: [AuthGuard, AdminAuthGuard] 
  },
  { 
    path: 'admin/news/add', 
    component: AddNewsComponent, 
    canActivate: [AuthGuard, AdminAuthGuard] 
},
{ 
  path: 'admin/news/edit/:id', 
  component: EditNewsComponent, 
  canActivate: [AuthGuard, AdminAuthGuard] 
}
    ])
  ],
  declarations: [
    EditProjectComponent,
    AdminProjectsComponent,
    AddProjectComponent,
    AdminNewsComponent,
    AddNewsComponent,
    EditNewsComponent,
  ],
  providers: [
    AuthGuard,
    AdminAuthGuard,
  ]
})
export class AdminModule { }
