import { projectsServiceService } from '../../../../projects/services/projects-service.service';
import { Component, OnInit } from '@angular/core';
import { IProject } from '../../../../projects/models/Iproject';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin-projects',
  templateUrl: './admin-projects.component.html',
  styleUrls: ['./admin-projects.component.css']
})
export class AdminProjectsComponent{
  projects: IProject[];
  filteredProjects: any[];
  subscription: Subscription;
  
    constructor(private ProjectService: projectsServiceService) { 
      this.subscription = this.ProjectService.getAll()
      .map(actions => {
      return actions.map(action => ({ key: action.key, value: action.payload.val() }));
      })
      .subscribe(x => this.filteredProjects = this.projects = x)
      }
  
    filter(query: string) {
      this.filteredProjects = (query) ?
      this.projects.filter(f => f.value.title.toLowerCase().includes(query.toLowerCase())) :
      this.projects;
      }

    delete(key){
      if(!confirm('are you sur you want to delete this project?')) return;
      
      this.ProjectService.delete(key);
    }
  
    ngOnDestroy(){
      this.subscription.unsubscribe();
    }
  
    ngOnInit() {
    }
  
}