import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppUser } from '../shared/models/app-user';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { NavbarService } from './navbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailModalComponent } from '../mail-modal/mail-modal.component';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  appUser: AppUser;

  constructor(private auth: AuthService,
    public nav: NavbarService,
    private modalService: NgbModal) { 
    auth.appUser$.subscribe(appUser => {
      this.appUser = appUser
    })
  }

  mailModal() {
    const modalRef = this.modalService.open(MailModalComponent);
    modalRef.componentInstance.id = 10; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  Logout() {
    this.auth.logout();
  }


  ngOnInit() {
  }

}
