import { IProject } from '../../../../projects/models/Iproject';
import { projectsServiceService } from '../../../../projects/services/projects-service.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ɵROUTER_PROVIDERS } from '@angular/router';
import 'rxjs/add/operator/take';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css'],
})
export class AddProjectComponent implements OnInit {
  project = {} as IProject;
  id;

    constructor(
      private ProjectService: projectsServiceService, 
      private router: Router,
      private route: ActivatedRoute) { 
  
      this.id = this.route.snapshot.paramMap.getAll('id');

    }
  
    save(project){
      if (this.id != "") this.ProjectService.update(this.id, project);
      else this.ProjectService.create(project);
  
      this.router.navigate(['/admin/project'])
    }
  
    delete(){
      if(!confirm('are you sur you want to delete this project?')) return;
      
      this.ProjectService.delete(this.id);
      this.router.navigate(['/admin/project']);
    }
  
    ngOnInit() {
    }

}
