import { Injectable } from '@angular/core';
import { Subject } from '../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  visible: boolean;
  private _subject = new Subject<any>();
  
  newEvent(event) {
    this._subject.next(event);
  }

  get events$ () {
    return this._subject.asObservable();
  }
}
