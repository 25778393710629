import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class projectsServiceService {

  observableProjects$: Observable<any[]>;

  constructor(private db: AngularFireDatabase) { 
    this.observableProjects$ = this.db
    .list('/projects')
    .snapshotChanges();  
  }

  create(project){
    return this.db.list('/projects').push(project);
  }

  getAll() {
    return this.observableProjects$;
  }

  get(projectId) {
    return this.db.object('/projects/' + projectId).snapshotChanges();
  }

  update(projectId, project){
    return this.db.object('/projects/' + projectId).update(project);
  }

  delete(projectId){
    return this.db.object('projects/' + projectId).remove();
  }
}
