import { Component, OnInit } from '@angular/core';
import { IProject } from './models/Iproject';
import { Subscription } from 'rxjs';
import { projectsServiceService } from './services/projects-service.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: IProject[];
  subscription: Subscription;
  
    constructor(private ProjectService: projectsServiceService) { 
      this.subscription = this.ProjectService.getAll()
      .map(actions => {
      return actions.map(action => ({ key: action.key, value: action.payload.val() }));
      })
      .subscribe(x => this.projects = x)
      }
      
  ngOnInit() {
  }

}
