import { Component, OnInit } from '@angular/core';
import {Http, HttpModule} from '@angular/http';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
$stackOverFlowData;
reputation;
bronzeBadge;
silverBadge;
goldBadge;

  constructor(private http: Http) { 
  }

getStackOverFlowData() {
  return this.http.get('https://api.stackexchange.com/2.2/users?order=desc&sort=reputation&inname=christophe%20gudlake&site=stackoverflow').map((res) => res.json());
}
  ngOnInit() {
    this.$stackOverFlowData = this.getStackOverFlowData().subscribe(data => 
      {
        this.reputation = data.items[0].reputation;
        this.bronzeBadge = data.items[0].badge_counts.bronze;
        this.silverBadge = data.items[0].badge_counts.silver;
        this.goldBadge = data.items[0].badge_counts.gold;
      });
  }

}
