import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { INews } from '../../../../news/models/INews';
import { NewsService } from '../../../../news/services/news.service';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements OnInit {

  news= {} as INews;
  
  id;
  news$: Subscription;

    constructor(
      private newsService: NewsService, 
      private router: Router,
      private route: ActivatedRoute) { 
  
      this.id = this.route.snapshot.paramMap.getAll('id');
     
      this.news$ = this.newsService.get(this.id).subscribe(
        action => {
          this.news = { key : action.key, value : Object(action.payload.val()) }
          console.log(this.news.value);
          console.log(this.news.key);
        // this.news.value.title = Object.values(this.news.value.title);
      });

     }

  
    save(news){
      if (this.id != "") this.newsService.update(this.id, news);
      else this.newsService.create(news);
  
      this.router.navigate(['/admin/news'])
    }
  
    delete(){
      if(!confirm('are you sur you want to delete this news?')) return;
      
      this.newsService.delete(this.id);
      this.router.navigate(['/admin/news']);
    }
  
    ngOnInit() {
    }

}
